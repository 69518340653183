import { ROLE_ACEL, ROLE_COMMUTEIQ } from '@/stores/global-constants';

export const commuteIQPaths = {
  commuteIQReportsList: {
    path: '/commute-iq',
    name: 'commute-iq-report-list',
  },
  adminCommuteIQReportsList: {
    path: '/admin/commute-iq',
    name: 'admin-commute-iq-report-list',
  },
  commuteIQReportGenerate: {
    path: '/commute-iq/report-generate',
    name: 'commute-iq-report-generate',
  },
  commuteIQSnapshotGenerate: {
    path: '/commute-iq/report/:reportId/create-snapshot',
    name: 'commute-iq-snapshot-generate',
  },
  commuteIQGeocodingStatus: {
    path: '/commute-iq/geocoding-status/:reportId',
    name: 'commute-iq-geocoding-status',
  },
  commuteIQReport: {
    path: '/commute-iq/report/:reportId',
    name: 'commute-iq-report',
  },
  commuteIQMap: {
    path: '/commute-iq/map/:reportId',
    name: 'commute-iq-map',
  },
  commuteIQDisabled: {
    path: '/commute-iq-info',
    name: 'commute-iq-disabled',
  }
};

export const commuteIQRoutes = [
  {
    path: commuteIQPaths.commuteIQReportsList.path,
    name: commuteIQPaths.commuteIQReportsList.name,
    component: () => import('@/features/commute-iq/screens/ReportsList.vue'),
    meta: {
      title: 'Commute IQ > Reports',
      rolesToPermit: ROLE_COMMUTEIQ,
      nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
    },
  },
  {
    path: commuteIQPaths.adminCommuteIQReportsList.path,
    name: commuteIQPaths.adminCommuteIQReportsList.name,
    component: () => import('@/features/admin/screens/CommuteIqListAllReports.vue'),
    meta: {
      title: 'Commute IQ > All Reports',
      rolesToPermit: ROLE_COMMUTEIQ,
      nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
    },
  },
{
  path: commuteIQPaths.commuteIQReportGenerate.path,
    name: commuteIQPaths.commuteIQReportGenerate.name,
  component: () => import('@/features/commute-iq/screens/GenerateReport.vue'),
  meta: {
  title: 'CommuteIQ > Generate report',
    rolesToPermit: ROLE_COMMUTEIQ,
    nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
},
},
{
  path: commuteIQPaths.commuteIQSnapshotGenerate.path,
    name: commuteIQPaths.commuteIQSnapshotGenerate.name,
  component: () => import('@/features/commute-iq/screens/GenerateSnapshot.vue'),
  meta: {
  title: 'CommuteIQ > Generate snapshot',
    rolesToPermit: ROLE_COMMUTEIQ,
    nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
},
},
{
  path: commuteIQPaths.commuteIQGeocodingStatus.path,
    name: commuteIQPaths.commuteIQGeocodingStatus.name,
  component: () => import('@/features/commute-iq/screens/GeocodingStatus.vue'),
  meta: {
  title: 'CommuteIQ > Geocoding status',
    rolesToPermit: ROLE_COMMUTEIQ,
    nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
},
},
{
  path: commuteIQPaths.commuteIQReport.path,
    name: commuteIQPaths.commuteIQReport.name,
  component: () => import('@/features/commute-iq/screens/ReportDetails.vue'),
  meta: {
  title: 'CommuteIQ > Report',
    allowAnonymous: true,
  // No permissions options because some reports are public
},
},
{
  path: commuteIQPaths.commuteIQMap.path,
    name: commuteIQPaths.commuteIQMap.name,
  component: () => import('@/features/commute-iq/screens/Map.vue'),
  meta: {
  title: 'CommuteIQ > Report map',
    rolesToPermit: ROLE_ACEL,
    nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
    allowAnonymous: true,
},
},

{
  path: commuteIQPaths.commuteIQDisabled.path,
    name: commuteIQPaths.commuteIQDisabled.name,
  component: () => import('@/features/commute-iq/screens/About.vue'),
  meta: {
  title: 'CommuteIQ > About',
},
}
]
