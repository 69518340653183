/**
 * @todo Remove all token refresh functionality from api requests and instead have the refreshes taken place
 * one minute before expiry. This cannot be done before we've addressed the issue of the worker's initial tab being closed.
 */

import { DEFAULT_HEADERS } from '@/api/legacy/constants';
import { type Method } from '@/api/legacy/types';
import { Headers as RequestHeaders } from '@/api/legacy/enums';

import storage from '@/core/helpers/storage'; // Adding so we can store isRefreshingToken in local storage instead of state
import { getApiDomain } from '@/api//helpers';

class MwApiBase {
  apiDomain: string;

  constructor() {
    this.apiDomain = this.getApiDomain();
  }

  async xhr<SendType, ResponseType>(
    method: Method,
    data: SendType,
    path: string,
    requiresAuth: boolean = true,
    retryCount: number = 0,
  ): Promise<ResponseType> {
    const response = await fetch(`${this.apiDomain}${path}`, {
      method,
      body: this.getRequestBody<SendType>(data),
      headers: this.getRequestHeaders<SendType>(data, requiresAuth),
    });
    const isJson = response.headers.get('content-type')?.includes('application/json');

    if (response.ok) {
      // This has been like this since before the below refactor, but quite why I'm not sure....
      return isJson ? await response.json() : await response.blob();
    } else {
      // If the app has loaded with a stale token, the first attempt will fail while the refresh is taking place.
      // Hence we retry. This mirrors the behaviour of the new Vue Query based API.
      const tokenExpired = response.headers.get('token-expired');
      if (retryCount < 3 && tokenExpired === 'true') {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return this.xhr<SendType, ResponseType>(method, data, path, requiresAuth, retryCount + 1);
      } else {
        throw response;
      }
    }
  }

  getRequestBody<SendType>(data: SendType) {
    return data instanceof FormData
      ? data // @ts-expect-error
      : Object.keys(data).length !== 0
      ? JSON.stringify(data)
      : undefined;
  }

  getRequestHeaders<SendType>(data: SendType, requiresAuth: boolean): Headers {
    const requestHeaders = new Headers({ ...DEFAULT_HEADERS });
    if (data instanceof FormData) {
      requestHeaders.delete(RequestHeaders.ContentType);
    }
    if (requiresAuth) {
      requestHeaders.set(RequestHeaders.Authorization, `Bearer ${storage.get('token')}`);
    }
    return requestHeaders;
  }

  getApiDomain() {
    return getApiDomain();
  }
}

export default MwApiBase;
