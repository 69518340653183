/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Mobilityways Private API
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  RequestAlimcqi,
  RequestWxrphkq,
  RequestY7sqmma,
  ResponseJt2aiua,
  ResponseKhqoubi,
  ResponseLmizl3q,
  UnauthorizedResultMwu4udi,
  ValidationExceptionResponseBaseDto65ui2ua
} from '.././model'
import { customInstance } from '../../../axios-instance';



/**
 * Used to generate an admin JWT using Liftshare dashboard user credentials. 
Token is valid for 30 min. 
Token can be refreshed using the refresh token up to 90 days after issue.
 * @summary Generate authentication token
 */
export const postClientToken = (
    requestY7sqmma: MaybeRef<RequestY7sqmma>,
 ) => {
      requestY7sqmma = unref(requestY7sqmma);
      
      return customInstance<ResponseLmizl3q>(
      {url: `/client/token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestY7sqmma
    },
      );
    }
  


export const getPostClientTokenMutationOptions = <TError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postClientToken>>, TError,{data: RequestY7sqmma}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postClientToken>>, TError,{data: RequestY7sqmma}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postClientToken>>, {data: RequestY7sqmma}> = (props) => {
          const {data} = props ?? {};

          return  postClientToken(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostClientTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postClientToken>>>
    export type PostClientTokenMutationBody = RequestY7sqmma
    export type PostClientTokenMutationError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua

    /**
 * @summary Generate authentication token
 */
export const usePostClientToken = <TError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postClientToken>>, TError,{data: RequestY7sqmma}, TContext>, }
): UseMutationReturnType<
        Awaited<ReturnType<typeof postClientToken>>,
        TError,
        {data: RequestY7sqmma},
        TContext
      > => {

      const mutationOptions = getPostClientTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Used to refresh an expired admin JWT using a one time refresh token. 
New token is valid for 30 min. 
Token can be refreshed again using the refresh token up to 90 days after issue.
 * @summary Refresh authentication token
 */
export const postClientTokenRefresh = (
    requestWxrphkq: MaybeRef<RequestWxrphkq>,
 ) => {
      requestWxrphkq = unref(requestWxrphkq);
      
      return customInstance<ResponseJt2aiua>(
      {url: `/client/token/refresh`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestWxrphkq
    },
      );
    }
  


export const getPostClientTokenRefreshMutationOptions = <TError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postClientTokenRefresh>>, TError,{data: RequestWxrphkq}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postClientTokenRefresh>>, TError,{data: RequestWxrphkq}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postClientTokenRefresh>>, {data: RequestWxrphkq}> = (props) => {
          const {data} = props ?? {};

          return  postClientTokenRefresh(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostClientTokenRefreshMutationResult = NonNullable<Awaited<ReturnType<typeof postClientTokenRefresh>>>
    export type PostClientTokenRefreshMutationBody = RequestWxrphkq
    export type PostClientTokenRefreshMutationError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua

    /**
 * @summary Refresh authentication token
 */
export const usePostClientTokenRefresh = <TError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postClientTokenRefresh>>, TError,{data: RequestWxrphkq}, TContext>, }
): UseMutationReturnType<
        Awaited<ReturnType<typeof postClientTokenRefresh>>,
        TError,
        {data: RequestWxrphkq},
        TContext
      > => {

      const mutationOptions = getPostClientTokenRefreshMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Takes an expired token and a one time two factor code and return a valid token and refresh token if successful.
 * @summary Validate two factor one time code
 */
export const postClientTokenValidateTwoFactor = (
    requestAlimcqi: MaybeRef<RequestAlimcqi>,
 ) => {
      requestAlimcqi = unref(requestAlimcqi);
      
      return customInstance<ResponseKhqoubi>(
      {url: `/client/token/validate-two-factor`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestAlimcqi
    },
      );
    }
  


export const getPostClientTokenValidateTwoFactorMutationOptions = <TError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postClientTokenValidateTwoFactor>>, TError,{data: RequestAlimcqi}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postClientTokenValidateTwoFactor>>, TError,{data: RequestAlimcqi}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postClientTokenValidateTwoFactor>>, {data: RequestAlimcqi}> = (props) => {
          const {data} = props ?? {};

          return  postClientTokenValidateTwoFactor(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostClientTokenValidateTwoFactorMutationResult = NonNullable<Awaited<ReturnType<typeof postClientTokenValidateTwoFactor>>>
    export type PostClientTokenValidateTwoFactorMutationBody = RequestAlimcqi
    export type PostClientTokenValidateTwoFactorMutationError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua

    /**
 * @summary Validate two factor one time code
 */
export const usePostClientTokenValidateTwoFactor = <TError = UnauthorizedResultMwu4udi | ValidationExceptionResponseBaseDto65ui2ua,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postClientTokenValidateTwoFactor>>, TError,{data: RequestAlimcqi}, TContext>, }
): UseMutationReturnType<
        Awaited<ReturnType<typeof postClientTokenValidateTwoFactor>>,
        TError,
        {data: RequestAlimcqi},
        TContext
      > => {

      const mutationOptions = getPostClientTokenValidateTwoFactorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    