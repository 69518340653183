export const accountPaths = {
  accountSettings: {
    path: '/account/settings',
    name: 'account-settings',
  },
};

export const accountRoutes = [
  {
    path: accountPaths.accountSettings.path,
    name: accountPaths.accountSettings.name,
    component: () => import('@/features/account/screens/Settings.vue'),
    meta: {
      title: 'Account settings',
    },
  },
];
