import {
  type UserRole,
  ROLE_ACEL_ADMIN,
  ROLE_ADMIN,
  ROLE_CONTACTS,
  ROLE_TRAVELPLAN,
  ROLE_COMMUTEIQ,
  ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN,
  ROLE_SUPERADMIN,
  ROLE_COMMUTE_BOOST_ADMIN,
} from '@/stores/global-constants';
import paths from '@/router/paths';

// Paths for individual features
import { acelPaths } from '@/features/acel/routes';
import { commuteBoostPaths } from '@/features/commute-boost/routes';
import { commuteIQPaths } from '@/features/commute-iq/routes';

import type { NavItem } from '@/features/navigation/types';

export const NAVIGATION_ITEMS: NavItem[] = [
  {
    id: 'dashboards',
    label: 'Dashboards',
    role: ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN,
    menuData: [
      {
        id: 'dashboards-low-frequency',
        text: 'Commute emissions dashboard',
        path: paths.dashboardLowFrequency.name,
        role: ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN,
      },
      {
        id: 'dashboards-high-frequency',
        text: 'Commute activity dashboard',
        path: paths.dashboardHighFrequency.name,
        role: ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN,
      },
      {
        id: 'dashboards-liftshare',
        text: 'Liftshare dashboard',
        path: paths.dashboardLiftshare.name,
        role: ROLE_CONTACTS,
      },
      //{ id: 'dashboards-zeelo', text: 'Zeelo dashboard', path: paths.dashboardZeelo.name },
    ],
  },
  {
    id: 'contacts',
    label: 'Contacts',
    role: ROLE_CONTACTS,
    path: paths.contacts.name,
  },
  {
    id: 'commuteiq',
    label: 'CommuteIQ',
    menuData: [
      { id: 'commute-iq-report-list', text: 'CommuteIQ', path: commuteIQPaths.commuteIQReportsList.name },
      { id: 'scoping-home', text: 'Scoping', path: paths.scoping.name },
    ],
  },
  {
    id: 'surveys',
    label: 'Surveys',
    path: 'survey-home',
  },
  {
    id: 'acel',
    label: 'ACEL',
    menuData: [
      { id: 'acel-dashboard', text: 'ACEL Dashboard', path: acelPaths.acel.name },
      { id: 'acel-reports', text: 'ACEL Reports', path: acelPaths.acelReports.name },
      {
        id: 'acel-generator',
        text: 'ACEL Generator',
        path: acelPaths.acelCalculator.name,
        role: ROLE_ACEL_ADMIN,
      },
    ],
  },
  {
    id: 'ptp',
    label: 'Personal Travel Plans',
    role: ROLE_TRAVELPLAN,
    menuData: [
      { id: 'ptp-dashboard', text: 'PTP Dashboard', path: paths.travelplans.name },
      { id: 'plans', text: 'Plans', path: paths.travelPlanList.name },
      { id: 'survey-results', text: 'Survey results', path: paths.travelplansSurvey.name },
      { id: 'widgets', text: 'Widgets', path: paths.travelplansWidget.name },
      { id: 'custom-texts', text: 'Custom texts', path: paths.travelplansTexts.name },
    ],
  },
  {
    id: 'liftshare',
    label: 'Liftshare',
    role: ROLE_ADMIN,
    menuData: [
      { id: 'liftshare', text: 'Liftshare', path: paths.Liftshare.name },
      {
        id: 'liftshare-invites',
        text: 'Liftshare Invites',
        path: paths.pendingLiftshareAccounts.name,
      },
    ],
  },
  {
    id: 'commute-boost',
    label: 'CommuteBoost',
    path:  commuteBoostPaths.commuteBoostChallengesList.name,
    role: ROLE_COMMUTE_BOOST_ADMIN,
  },
  {
    id: 'admin',
    label: 'Admin',
    menuData: [
      {
        id: 'admin-commute-iq-list-all',
        text: 'All CommuteIQ reports',
        path: commuteIQPaths.adminCommuteIQReportsList.name,
      },
      {
        id: 'admin-survey-list-all',
        text: 'All surveys',
        path: paths.adminSurveyListAll.name,
      },
      {
        id: 'pending-liftshare-accounts',
        text: 'Pending Liftshare accounts',
        path: paths.adminPendingLiftshare.name,
      },
      { id: 'notifications', text: 'Notifications', path: paths.adminNotifications.name },
      { id: 'platform-usage', text: 'Platform usage', path: paths.adminPlatformUsage.name },
      { id: 'full-scoping', text: 'Full scoping', path: paths.scopingFull.name },
      // {
      //   id: 'high-frequency',
      //   text: 'High frequency imports',
      //   path: paths.adminHF.name,
      // },
      {
        id: 'admin-tracking',
        text: 'Tracking (debug)',
        path: paths.adminTracking.name,
      },
      {
        id: 'admin-ghg-factor-sets',
        text: 'GHG factor sets',
        path: paths.adminGHGFactorSets.name,
      },
    ],
    role: ROLE_ADMIN,
    showBorder: true,
  },
  {
    id: 'pd-only',
    label: 'PD only',
    menuData: [
      {
        id: 'challenges',
        text: 'CB Challenges: List',
        path: commuteBoostPaths.adminCommuteBoostChallenges.name,
        role: ROLE_COMMUTE_BOOST_ADMIN,
      },
      {
        id: 'create-challenge',
        text: 'CB Challenges: Create',
        path: commuteBoostPaths.adminCommuteBoostChallengesCreate.name,
        role: ROLE_COMMUTE_BOOST_ADMIN,
      },
      {
        id: 'redeem-boost',
        text: 'CB Boosts: Redeem',
        path: commuteBoostPaths.adminCommuteBoostRedeem.name,
        role: ROLE_COMMUTE_BOOST_ADMIN,
      },
      {
        id: 'admin-hf',
        text: 'Dashboards: HF/CA admin',
        path: paths.adminHF.name,
      },
    ],
    role: ROLE_SUPERADMIN,
  },
];
