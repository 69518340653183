import { MWApiV2 } from '@/api/legacy';
const api2 = new MWApiV2();

import type { DashboardLowFrequencyPayloadType, HighFrequencyChartDataPayload } from '@/api/types';

export default {
  // Low-frequency dashboard
  fetchLowFrequencyChartData: async (model: DashboardLowFrequencyPayloadType) => {
    return await api2.DashboardLowFrequencyChart(model);
  },
  fetchLowFrequencySummaryData: async (communityId: string) => {
    return await api2.DashboardLowFrequencySummary(communityId);
  },
  // Liftshare dashboard
  fetchLiftshareSummaryData: async (communityId: string) => {
    return await api2.DashboardLiftshareSummary(communityId);
  },
  fetchLiftshareMembersData: async (communityId: string) => {
    return await api2.DashboardLiftshareMembers(communityId);
  },
  fetchLiftshareJourneysData: async (communityId: string) => {
    return await api2.DashboardLiftshareJourneys(communityId);
  },
  fetchLiftshareRequestToSharesData: async (communityId: string) => {
    return await api2.DashboardLiftshareRequestToShares(communityId);
  },
  fetchLiftshareTeamsData: async (communityId: string) => {
    return await api2.DashboardLiftshareTeams(communityId);
  },
  fetchLiftshareCumulativeMembersData: async (communityId: string, granularity: number) => {
    return await api2.DashboardLiftshareCumulativeMembers(communityId, granularity);
  },
  fetchLiftshareTripAuthenticationsLeaderboardData: async (communityId: string) => {
    return await api2.DashboardLiftshareTripAuthenticationsLeaderboard(communityId);
  },
  fetchLiftshareTripAuthenticationsData: async (communityId: string, granularity: number) => {
    return await api2.DashboardLiftshareTripAuthentications(communityId, granularity);
  },

  // High-frequency imports
  fetchHighFrequencyDataSources: async () => {
    return await api2.HighFrequencyGetDataSources();
  },
  fetchHighFrequencyImports: async () => {
    return await api2.HighFrequencyGetImports();
  },
  createHighFrequencyImport: async (model: FormData) => {
    return await api2.HighFrequencyPostImport(model);
  },
  deleteHighFrequencyImport: async (id: string) => {
    return await api2.HighFrequencyDeleteImport(id);
  },
  deleteHighFrequencyImports: async (ids: string[]) => {
    return await api2.HighFrequencyDeleteImports(ids);
  },

  // High-frequency dashboard
  fetchHighFrequencyChartData: async (model: HighFrequencyChartDataPayload) => {
    return await api2.HighFrequencyGetChartData(model);
  },
  /**
   * @todo Make the API call for high-frequency summary data when backend is ready
   */
  // fetchHighFrequencySummaryData: async (communityId: string) => {
  //   return await api2.HighFrequencyGetSummaryData(communityId);
  // },
};
