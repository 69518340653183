<template>
      <div
        class="fixed left-0 right-0 top-0 bottom-0 flex-1"
        style="
        opacity: 0.5;
        background-size: cover;
        background-image: url('https://liftshare.blob.core.windows.net/static-assets/mobilityways/eric-veiga-CXF_lrsf54E-unsplash-cropped.jpg');
      "
      ></div>
</template>
