<script lang="ts" setup>
import { computed, ref } from 'vue';

const emit = defineEmits(['success', 'cancel']);

const props = defineProps<{
  seconds: number; // Number of seconds used for the countdown timer
  status: string; // Used to handle colours/icons, e.g. warning, error, info, success
  title: string; // Main title
  message: string; // Displayed below the title
  cancelButtonText: string; // Used as wording for the secondary CTA, e.g. Cancel
  primaryButtonText: string; // Used as wording for the primary CTA, e.g. Submit
}>();

const colour = ref('yellow');
if (props.status == 'success') colour.value = 'green';
if (props.status == 'warning') colour.value = 'yellow';
if (props.status == 'error') colour.value = 'red';
if (props.status == 'info') colour.value = 'blue';
</script>

<template>
  <transition name="fade">
    <div
      class="fixed z-50 left-0 top-0 bottom-0 right-0 bg-black bg-opacity-75"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6"
        >
          <div class="flex justify-between">
            <div class="mt-3 mb-8 text-center sm:mt-0 sm:text-left">
              <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                {{ props.title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500" v-html="props.message"></p>
              </div>
            </div>
            <div class="font-normal" v-if="props.seconds > 0">{{ props.seconds }}</div>
          </div>
          <div class="sm:flex sm:flex-row-reverse sm:justify-between">
            <button
              @click="emit('success')"
              type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-pri px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ props.primaryButtonText }}
            </button>
            <button
              @click.stop="emit('cancel')"
              type="button"
              class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            >
              {{ props.cancelButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
