export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  CommunityAdmin = 'CommunityAdmin',
  CommunityMonitor = 'CommunityMonitor',
  MemberActivator = 'MemberActivator',
  MemberExportAdmin = 'MemberExportAdmin',
  RequiresClassicReporting = 'RequiresClassicReporting',
  CommunityParkingPermit = 'CommunityParkingPermit',
  PaidSupportLicence = 'PaidSupportLicence',
  TravelPlanAdmin = 'TravelPlanAdmin',
  TravelPlanMonitor = 'TravelPlanMonitor',
  ScopingUser = 'ScopingUser',
  SurveyAndAcelAdmin = 'SurveyAndAcelAdmin',
  SurveyAndAcelMonitor = 'SurveyAndAcelMonitor',
  ContactAdmin = 'ContactAdmin',
  ContactMonitor = 'ContactMonitor',
  AcelTargetAdmin = 'AcelTargetAdmin',
  ScopingAdmin = 'ScopingAdmin',
  MobilitywaysCommuteActivityDashboardAdmin = 'MobilitywaysCommuteActivityDashboardAdmin',
  MobilitywaysCommuteBoostAdmin = 'MobilitywaysCommuteBoostAdmin',
}
// Using strings here so we can have our own custom ordering
export enum MobilitywaysTransportEnum {
  WorkFromHome = '0',
  WalkOrWheel = '1',
  Run = '23',
  Cycle = '2',
  Drive = '12',
  ElectricDrive = '13',
  Hybrid = '27',
  BusOrCoach = '4',
  ElectricBusOrCoach = '5',
  Carshare = '9',
  ElectricCarshare = '10',
  HybridCarshare = '28',
  Train = '7',
  Motorcycle = '8',
  ElectricMotorcycle = '22',
  ParkAndRide = '11',
  ElectricScooter = '3',
  LightRail = '6',
  Tube = '24',
  Metro = '25',
  Tram = '26',
  CompanyShuttleBus = '20',
  ElectricCompanyShuttleBus = '21',
  Taxi = '14',
  ElectricTaxi = '15',
  VanPool = '18',
  ElectricVanPool = '19',
  Fly = '16',
  Other = '17',
}

export enum ImportStatusEnum {
  Pending,
  Processing,
  Complete,
  Failed,
}
export enum CommuteIqReportStatus {
  Draft,
  Geocoding,
  Geocoded,
  Finalized,
  Generating,
  Generated,
  Aggregating,
  Aggregated,
  Complete,
  Failed,
}
