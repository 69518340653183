import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1800 1123.575"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#f163c1",
      d: "M0 36.051s403-216.153 939.576 410.083S1674.894 133.349 1920 302.708s0 820.866 0 820.866H0Z",
      "data-name": "Path 110"
    }, null, -1)
  ])))
}
export default { render: render }