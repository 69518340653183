<template>
  <div
    :data-cy="`nav-dropdown-${id}`"
    class="cursor-pointer"
    @mouseenter="data.showMenu = true"
    @mouseleave="data.showMenu = false"
  >
    <div
      class="text-sm text-gray-100 font-medium p-4 hover:bg-gray-900 relative"
      :class="data.showBorder ? 'ml-8' : ''"
      v-bind="$attrs"
    >
      <div v-if="data.showBorder" class="absolute border-l h-6 top-0 bottom-0 m-auto -ml-8"></div>

      <slot></slot>
      <i v-if="menuData" class="fas fa-chevron-down ml-2 text-xs cursor-pointer text-pri" />
    </div>
    <div v-show="data.menuData && data.showMenu" class="static lg:absolute z-40 w-full lg:w-auto">
      <router-link
        v-for="(item, index) in data.menuData"
        :data-cy="`nav-link-${item.id}`"
        :key="index"
        :to="{ name: item.path }"
        class="text-sm text-gray-100 font-medium p-4 pl-8 lg:pl-4 hover:bg-gray-900 lg:bg-gray-800 block"
      >
        {{ item.text }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'HeaderLinkMenu',
  inheritAttrs: false,
  props: {
    routerLink: String, // vue router identifier for this link
    menuData: Object, // data to populate menu list text/router names
    showBorder: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },

  setup(props) {
    const data = reactive({
      routerLink: props.routerLink,
      menuData: props.menuData,
      showBorder: props.showBorder,
      showMenu: false,
    });

    return {
      data,
    };
  },
});
</script>
