export enum TravelPlanSwitchType {
  Recommended = 'Recommended',
  WalkOrWheel = 'Walk',
  Bike = 'Bike',
  Carshare = 'Carshare',
  Public = 'Public',
  Drive = 'Drive',
}

export enum TravelPlanEmailTabStatus {
  TravelPlanEmail,
  BehaviourChangeEmail,
  FollowUpEmail,
}
export enum CreateWidgetModalType {
  Exit,
}
export enum WidgetStatus {
  Draft,
  Active,
  Paused,
}

export enum WidgetType {
  Iframe,
  Button,
  Url,
}

export enum WidgetRateLimitStatus {
  None,
  Near,
  Exceeded,
}

export enum WidgetRestrictionType {
  None,
  Origin,
  Destination,
  Both,
}

export enum WidgetSendEmailOption {
  DontSend,
  Optional,
  Send,
}

export enum WidgetButtonRadius {
  None,
  Small,
  Medium,
  Large,
  Full,
}

export enum WidgetButtonFontSize {
  XSmall,
  Small,
  Medium,
  Large,
  XLarge,
  XXLarge,
}
export enum TravelPlanLegType {
  Outbound,
  Return,
}
export enum TravelPlanRateLimitStatus {
  Under,
  Warning,
  Over,
}
export enum ContactPreferenceType {
  MarketingInformation = 0,
  Surveys = 1,
  TravelPlans = 2,

  // Not really a preference since you cannot opt out, but included here
  // so we can use the same component
  PendingLiftshareAccounts = 2,
}
export enum PassengerOrDriver {
  Passenger,
  Driver,
}
export enum ContactsFilterType {
  None,
  InvalidEmail,
  Unsubscribed,
  Suppression,
  NoAddress,
  AlreadyInPendingLiftshareAccountFlow,
}

export enum RecipientsFilterType {
  IndividualContact,
  FilterCriteria,
}

export enum TravelPlanBatchStatus {
  Draft = 0,
  RecipientPlansFinalized = 1,
  GeneratingPlans = 2,
  GeneratingPlansComplete = 3,
  FinalizedAndReadyToSend = 4,
  Scheduled = 5,
  Sent = 6,
  Cancelled = 7,
}
export enum JourneyFrequency {
  OneOff = 1,
  Regular = 2,
  Occasional = 3,
  Shift = 4,
}
export enum PtpTabStatus {
  Draft,
  Results,
  Breakdown,
  Liftshare,
}

export enum TravelPlanBatchDelayType {
  DoNotSend,
  Immediately,
  TimedDelay,
}

export enum TravelPlanBatchRecipientsListType {
  Individual,
  ContactSearchFilter,
  BatchUpload,
}
export enum TravelPlanFailReason {
  Unknown = 0,
  UnableToRoute = 1,
  NoValidRoutes = 2,
}
export enum TravelPlanServiceType {
  WalkOrWheel = 'Walk',
  Bike = 'Bike',
  Public = 'Public',
  Drive = 'Drive',
  ParkAndRide = 'ParkAndRide',
  CycleToTrainStation = 'CycleToTrainStation',
  DriveToTrainStation = 'DriveToTrainStation',
  CarShareToTrainStation = 'CarShareToTrainStation',
  Carshare = 'Carshare',
  CommunityTransport = 'CommunityTransport',
}
export enum TravelPlanSource {
  Individual = 0,
  Batch = 1,
  Widget = 2,
  Other = 100,
}
export enum TravelPlanStatus {
  Draft,
  NotSent,
  Scheduled,
  Sent,
  Failed,
}
export enum TravelPlansListOrderBy {
  DateCreatedUtc,
  Status,
  DateEmailSentUtc,
  PlanOnlineViews,
  DateSurveySentUtc,
  BatchName,
  ContactName,
}
export enum BatchListStatus {
  Draft = 0,
  NotSent = 1,
  Scheduled = 2,
  Sent = 3,
}
export enum JourneyType {
  Passenger = 1,
  Driver = 2,
  Both = 3,
}

export enum DashboardAppActionTypes {
  LOGOUT = 'LOGOUT',
  GET_ACCOUNTS_LIST = 'GET_ACCOUNTS_LIST',
  ON_ACCOUNT_CHANGE = 'ON_ACCOUNT_CHANGE',
  GET_CURRENT_FROM_STORE = 'GET_CURRENT_FROM_STORE',
  SEARCH_TERM_CHANGE = 'SEARCH_TERM_CHANGE',
}
