<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import NoApiWave from '@/assets/svg/no-api-wave.svg?component';
import NoApiImage from '@/assets/svg/no-api.svg?component';

// Stores
import { useGlobalStore } from '@/stores/global-store';
const globalStore = useGlobalStore();

const seconds = ref<number>(5);

let countdownTimer: ReturnType<typeof setInterval> | undefined;
const networkTimer = setInterval(async () => {
  await globalStore.initGlobalStore();
  seconds.value = 5;
  clearInterval(countdownTimer);
  countdownTimer = setInterval(() => {
    seconds.value--;
  }, 1000);
}, 5000);

onMounted(() => {
  // Set an initial timer to
  countdownTimer = setInterval(() => {
    seconds.value--;
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(networkTimer);
  clearInterval(countdownTimer);
});
</script>

<template>
  <div class="outer">
    <div
      style="width: 300px; background-color: rgba(255, 255, 255, 0.5)"
      class="absolute right-0 z-10"
    >
      <img
        style="width: 100%; height: auto"
        src="https://cdn.liftshare.com/static-assets/mobilityways/dashboard/mw_full_logo_220.png"
        class="w-full h-auto"
      />
    </div>
    <NoApiImage class="no-api-img" />
    <NoApiWave class="wave" />
    <div class="relative m-auto max-w-md p-8 bg-pri text-white shadow-md z-5">
      <h1 class="text-6xl font-bold mb-4">Unable to connect</h1>
      <p class="text-lg">
        Sorry there seems to be an issue connecting you. Attempting reconnection in... {{ seconds }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.outer {
  display: flex;
  overflow: hidden;
  height: 100vh;
  z-index: -10;
}
.no-api-img {
  position: absolute;
  top: 0;
  right: 0;
}
.wave {
  position: absolute;
  bottom: 0;
}
</style>
