import { recipientsConditionLabels, recipientsCriteriaLabels } from '@/views/Scoping/constants';


export default {
  //converts string to slug
  slugify: (inputStr: string) => {
    let str = inputStr.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  },

  // converts an object to a SHA-256 hash - useful when comparing objects
  hashObject: async (obj: any): Promise<string> => {
    const str = JSON.stringify(obj, Object.keys(obj).sort());
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hash = await window.crypto.subtle.digest('SHA-256', data);
    return Array.from(new Uint8Array(hash))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  },
};


export const getMessagesFromFailures = (failures: any) =>
  Object.keys(failures).flatMap((key) => failures[key]);

type Env = 'localhost' | 'localdev' | 'development' | 'staging' | 'production' | 'aspire' | 'cypress' | 'remote';
/**
 * Get the current environment
 */
export const getEnv = (): Env => {
  if (import.meta.env.MODE === 'remote') return 'remote';
  if (import.meta.env.MODE === 'localdev') return 'localdev';
  if (import.meta.env.MODE === 'aspire') return 'aspire';
  if (import.meta.env.MODE === 'cypress') return 'cypress';
  if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1')
  )
    return 'localhost';
  if (window.location.hostname.includes('development')) return 'development';
  if (window.location.hostname.includes('staging')) return 'staging';
  return 'production';
};

export function debounce<T extends Function>(func: T, timeout: number = 300) {
  let timer: number;
  const callable = (...args: any[]) => {
    clearTimeout(timer);
    // @ts-expect-error
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
  return <T>(<any>callable);
}

/**
 * Generates a UUIDv4
 */
export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};


export const resetErrors = () => {
  const errors = document.querySelectorAll('.inlineError');
  const errorInputs = document.querySelectorAll('.inlineErrorInput');

  errors.forEach((element) => element.remove());
  errorInputs.forEach((element) => element.classList.remove('border-red-500'));
};

// add an error below any input, with optional generic object payload
export const addError = (errorMsg?: string, inputId?: string, payload?: object) => {
  if (inputId) {
    const input = document.querySelector(`#${inputId}`);

    if (input) {
      input.classList.add('border-red-500');

      if (errorMsg) {
        const newElement = document.createElement('p');
        newElement.className = 'text-red-500 font-bold mt-1 mb-6 inlineError shake';
        newElement.innerText = errorMsg;
        // @ts-expect-error
        input.parentNode.insertBefore(newElement, input.nextSibling);
      }
    }
  }

  return {
    errorMsg,
    payload,
    inputId,
  };
};

export const getErrorMessages = (errorData: any, fallback?: string) => {
  const { Failures } = errorData;
  return Failures !== undefined ? getMessagesFromFailures(Failures) : fallback;
};

// @ts-expect-error
export const getServerError = (response) => {
  const { Failures, Exception, Message } = response;
  // TODO: Fire some kind of Analytics tracking
  let errorMessage = 'Oops, something went wrong. Please try again later';
  if (typeof Message === 'string' && Message.length) {
    errorMessage = Message;
  }

  if (
    Exception !== null &&
    Exception !== undefined &&
    typeof Exception.Message === 'string' &&
    Exception.Message.length
  ) {
    errorMessage = Exception.Message;
  }

  if (Failures !== undefined) {
    if (Failures.Message !== undefined) {
      const { 0: msg } = Failures.Message;
      errorMessage = msg;
    } else {
      const {
        0: { 0: msg },
      } = Object.keys(Failures).map((key) => Failures[key]);
      errorMessage = msg;
    }
  }

  return errorMessage;
};

export const getCriteriaLabel = (key: string) => recipientsCriteriaLabels.find((e) => e.key === key)?.label || '';
export const getConditionLabel = (key: string) => recipientsConditionLabels.find((e) => e.key === key)?.label || '';
