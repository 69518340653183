<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';
import storage, { LAST_ACTIVITY, REFRESH_IN_PROGRESS } from '@/core/helpers/storage';
import PrivateLayout from '@/router/layouts/PrivateLayout.vue';
import EmptyLayout from '@/router/layouts/PrivateEmptyLayout.vue';
import AuthLayout from '@/router/layouts/AuthLayout.vue';
import { useRoute } from 'vue-router';
import { Layouts } from '@/features/navigation/enums';
import { useAuthStore } from '@/stores/auth/auth-store';
import AppConfirmModal from '@/core/components-v2/alerts/AppConfirmModal.vue';

const route = useRoute();

/**
 * There is a very rare edge case where a token refresh request has been made (so setting the isRefreshingToken to true) but it's failed for some reason.
 * This causes a blank screen issue, where the app fails because no API requests can be made - the isRefreshingToken being true is there to prevent requests being made
 * until a token has been refreshed. This continues even on a page refresh.
 * The blank screen issue is an app architecture issue, but the fix for this specific scenario is to reset the isRefreshingToken to false on a page refresh
 * so that API requests can go through again.
 */
storage.set(REFRESH_IN_PROGRESS, false);

const authStore = useAuthStore();

const setLastActivity = () => {
  const currentTime = Math.floor(Date.now());
  storage.set(LAST_ACTIVITY, currentTime);
};
onMounted(() => {
  /**
   * check token fresh, set timeout.
   * This is ok because Vue Query has retries which will buffer against initial fails on stale token.
   * See store comments.
   * TODO: https://vueuse.org/core/useEventListener/ ? Minor...
   */
  authStore.setRefreshTimeout();
  setLastActivity();
  window.addEventListener('click', setLastActivity);
});

onUnmounted(() => {
  /**
   * Clear is just needed for dev.
   * The timeout gets instatiated on the root component but that gets remounted on hot reload,
   * hence this is used on a lifecycle function
   */
  authStore.clearRefreshTimeout();
});
</script>

<template>
  <AuthLayout v-if="$route.meta.layout === Layouts.Auth" />
  <EmptyLayout v-else-if="$route.meta.layout === Layouts.PrivateEmpty" />
  <PrivateLayout v-else />
  <AppConfirmModal />
</template>
