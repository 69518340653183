import { Layouts } from '@/features/navigation/enums';

export const authPaths = {
  login: {
    path: '/',
    name: 'login',
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'forgot-password',
  },
  passwordReset: {
    path: '/account/passwordreset',
    name: 'password-reset',
  },
  setupMFA: {
    path: '/setup-mfa',
    name: 'setup-mfa',
  },
};

export const authRoutes = [
  {
    path: authPaths.login.path,
    name: authPaths.login.name,
    component: () => import('@/features/auth/screens/Login.vue'),
    meta: {
      title: 'Sign in',
      allowAnonymous: true,
      layout: Layouts.Auth,
    },
  },
  {
    path: authPaths.forgotPassword.path,
    name: authPaths.forgotPassword.name,
    component: () => import('@/features/auth/screens/ForgotPassword.vue'),
    meta: {
      title: 'Forgot password',
      allowAnonymous: true,
      layout: Layouts.Auth,
    },
  },
  {
    path: authPaths.passwordReset.path,
    name: authPaths.passwordReset.name,
    component: () => import('@/features/auth/screens/PasswordReset.vue'),
    props: (route: any) => ({ code: route.query.code }),
    meta: {
      title: 'Reset password',
      allowAnonymous: true,
      layout: Layouts.Auth,
    },
  },
  {
    path: authPaths.setupMFA.path,
    name: authPaths.setupMFA.name,
    component: () => import('@/features/auth/screens/SetupMFA.vue'),
    meta: {
      title: 'Setup MFA',
      allowAnonymous: true,
      layout: Layouts.Auth,
    },
  }
];
