export const recipientsCriteriaLabels = [
  { key: 'FirstName', label: 'First name' },
  { key: 'LastName', label: 'Last name' },
  { key: 'Email', label: 'Email address' },
  { key: 'HomeAddress', label: 'Home address' },
  { key: 'DateCreatedUtc', label: 'Created date' },
  { key: 'Tags', label: 'Contact tags' },
];

export const recipientsConditionLabels = [
  { key: 'EqualTo', label: 'is equal to' },
  { key: 'NotEqualTo', label: 'is not equal to' },
  { key: 'StartsWith', label: 'starts with' },
  { key: 'EndsWith', label: 'ends with' },
  { key: 'Contains', label: 'contains' },
  { key: 'DoesNotContain', label: 'does not contain' },
  { key: 'hasAtLeastOneOf', label: 'equal to any of' },
  { key: 'hasNoneOf', label: 'not equal to any of' },
  { key: 'Known', label: 'is known' },
  { key: 'Unknown', label: 'is unknown' },
  { key: 'LessThan', label: 'is less than' },
  { key: 'GreaterThan', label: 'is greater than' },
];

export const ScopingCostPerAddress = 0.03;
