<script lang="ts" setup>
import { reactive } from 'vue';
import { isExternalUrl } from '@/core/helpers/url-helpers';

const { pathName } = defineProps<{
  pathName: string;
}>();

const data = reactive({
  routerLink: pathName,
  isExternalUrl: isExternalUrl(pathName),
  classes: 'text-sm text-gray-100 font-medium p-4 hover:bg-gray-900',
});
</script>
<template>
  <a v-if="data.isExternalUrl" :class="data.classes" :href="data.routerLink" target="_blank">
    <slot></slot>
  </a>
  <router-link v-else :to="{ name: data.routerLink }" :class="data.classes">
    <slot></slot>
  </router-link>
</template>
