/**
 * Local storage keys
 */
export const STORAGE_TOKEN = 'token';
export const STORAGE_REFRESH_TOKEN = 'refreshToken';
export const REFRESH_IN_PROGRESS = 'refreshInProgress';
export const LAST_ACTIVITY = 'lastActivity';

/**
 * Get item from local storage
 */
function get<T>(key: string): T | null {
  try {
    const value = localStorage.getItem(key) ?? '';
    // if(import.meta.env.MODE === 'development') console.log(`store/${key} (GET) => ${value || 'null'}`);
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}

/**
 * Set item in local storage
 */
function set<T>(key: string, value: T): void {
  const strValue = JSON.stringify(value);
  // if(import.meta.env.MODE === 'development') console.log(`store/${key} (SET) => ${value || 'null'}`);
  localStorage.setItem(key, strValue);
}

/**
 * Remove item from local storage
 */
function remove(key: string): void {
  localStorage.removeItem(key);
}

export default {
  get,
  set,
  remove,
};
