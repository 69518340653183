import { MWApi } from '@/api/legacy';
import type { AccountSettings } from '@/api/types';

const api = new MWApi();

//TODO: Check LS for default per page, if not found, get from API
export default {
  getAccountDefaultPerPage: async (): Promise<number> => {
    try {
      const accountPrefs: AccountSettings = await api.AccountSettings();

      if (accountPrefs.paginationRowsPerPageDefault) {
        return Number(accountPrefs.paginationRowsPerPageDefault);
      }
      return 25;
    } catch {
      return 25;
    }
  },
};
