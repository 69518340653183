import { getEnv } from '@/core/helpers/generic-helpers';

/**
 * Get the API domain based on the current environment
 */
export const getApiDomain = () => {
  switch (getEnv()) {
    case 'cypress':
      return 'https://interface-dev.liftshare.com';
    case 'localdev':
    case 'aspire':
    case 'localhost':
      return 'https://localhost:5001';
    case 'development':
    case 'remote':
      return 'https://interface-dev.liftshare.com';
    case 'staging':
      return 'https://interface-staging.liftshare.com';
    case 'production':
    default:
      return 'https://interface.liftshare.com';
  }
};
