import { WidgetType } from '@/views/TravelPlan/enums';

type Env = 'localhost' | 'localdev' | 'development' | 'staging' | 'production' | 'aspire';
export const getEnv = (): Env => {
  if (import.meta.env.MODE === 'localdev') return 'localdev';
  if (import.meta.env.MODE === 'aspire') return 'aspire';
  if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1')
  )
    return 'localhost';
  if (window.location.hostname.includes('development')) return 'development';
  if (window.location.hostname.includes('staging')) return 'staging';
  return 'production';
};

export const getDomainMwWeb = () => {
  switch (getEnv()) {
    case 'localdev':
    case 'localhost':
    case 'aspire':
      return 'http://localhost:3000';
    case 'development':
      return 'https://app-development.mobilityways.co.uk';
    case 'staging':
      return 'https://app-staging.mobilityways.co.uk';
    case 'production':
    default:
      return 'https://app.mobilityways.com';
  }
};
export const getLiftshareDomain = () => {
  switch (getEnv()) {
    case 'aspire':
    case 'localdev':
    case 'localhost':
      return 'https://localhost:5001'
    case 'development':
      return 'https://development.liftshare.com';
    case 'staging':
      return 'https://staging.liftshare.com';
    case 'production':
    default:
      return 'https://liftshare.com';
  }
};

export function isExternalUrl(url: string): boolean {
  return typeof url === 'string' && url.startsWith('http');
};

export function getSnippetLinkOrCode(widgetType: WidgetType, widgetId: string | null): string {
  const planUrl = getSnippetLink(widgetType, widgetId);
  if (widgetType === WidgetType.Url) return planUrl;
  return `<iframe src="${planUrl}"></iframe>`;
};

export const getSnippetLink = (widgetType: WidgetType, widgetId: string | null) => {
  const baseUrl = `${getDomainMwWeb()}/travel-plan`;
  let embedOrwidget = 'embed';
  if (widgetType === WidgetType.Url) {
    embedOrwidget = 'widget';
  }
  const planUrl = `${baseUrl}/${embedOrwidget}/${widgetId}`;
  return planUrl;
};
