import { createI18n } from 'vue-i18n';
import enGB from '@/locales/en-GB.json';
import enGBNumbers from '@/locales/en-GB-numbers.json';

// Define a schema for the translations based on en-gb.json as the master
type TranslationSchema = typeof enGB;
type NumberSchema = typeof enGBNumbers;

const i18n = createI18n<{ messages: TranslationSchema, number: NumberSchema }, 'en-GB'>({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  legacy: false,
  messages: {
    'en-GB': enGB,
  },
  numberFormats: {
    'en-GB': {
      currency: {
        style: 'currency',
        currency: 'GBP',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      tonnes: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
      kg: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      },
      miles: {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      },
      km: {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      },
      m: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      minutes: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
});

export default i18n;
