import { ROLE_ACEL, ROLE_ACEL_ADMIN } from '@/stores/global-constants';

export const acelPaths = {
  acel: {
    path: '/acel/:reportId?',
    name: 'acel-home',
  },
  acelReports: {
    path: '/acel/reports',
    name: 'acel-reports',
  },
  acelReportDetails: {
    path: '/acel/report/:reportId',
    name: 'acel-reportdetails',
  },
  acelCalculator: {
    path: '/acel/generator',
    name: 'acel-calculator',
  },
  acelDisabled: {
    path: '/average-commuter-emissions-level',
    name: 'acel-disabled',
  },
};

export const acelRoutes = [
  {
    path: acelPaths.acel.path,
    name: acelPaths.acel.name,
    component: () => import('@/features/acel/screens/Index.vue'),
    meta: {
      title: 'ACEL',
      rolesToPermit: ROLE_ACEL,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: acelPaths.acelReports.path,
    name: acelPaths.acelReports.name,
    component: () => import('@/features/acel/screens/Reports.vue'),
    meta: {
      title: 'ACEL / Reports',
      rolesToPermit: ROLE_ACEL,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: acelPaths.acelReportDetails.path,
    name: acelPaths.acelReportDetails.name,
    component: () => import('@/features/acel/screens/ReportDetails.vue'),
    meta: {
      title: 'ACEL / Report details',
      rolesToPermit: ROLE_ACEL,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
      allowAnonymous: true,
    },
  },
  {
    path: acelPaths.acelCalculator.path,
    name: acelPaths.acelCalculator.name,
    component: () => import('@/features/acel/screens/Calculator.vue'),
    meta: {
      title: 'ACEL / Generator',
      rolesToPermit: ROLE_ACEL_ADMIN,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: acelPaths.acelDisabled.path,
    name: acelPaths.acelDisabled.name,
    component: () => import('@/features/acel/screens/Disabled.vue'),
    meta: {
      title: 'ACEL / Disabled',
    },
  },
];
