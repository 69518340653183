<script lang="ts" setup>
import Textarea from 'primevue/textarea';

const model = defineModel<string>();
const {
  name,
  rows = '5',
  required = false,
} = defineProps<{
  name: string;
  label: string;
  id: string;
  placeholder: string;
  helperText?: string;
  rows?: string;
  errorMessage?: string;
  required?: boolean;
}>();

defineEmits<{
  (event: 'unfocus'): void;
}>();
</script>
<template>
  <div data-spec="field-text" class="flex flex-col">
    <label :for="id" class="font-bold" :class="{ 'text-red-600': errorMessage }"
      >{{ label }} <span v-if="required" class="text-red-500">*</span></label
    >
    <Textarea
      v-model="model"
      :rows="rows"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :class="{ 'p-invalid': errorMessage }"
      @focusout="$emit('unfocus')"
    />
    <small v-if="helperText" class="text-eco-800 leading-none mt-1">{{ helperText }} </small>
    <small v-if="errorMessage" class="text-xs text-red-600">{{ errorMessage }}</small>
  </div>
</template>
