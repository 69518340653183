import { ROLE_COMMUTE_BOOST_ADMIN } from '@/stores/global-constants';
import { Layouts } from '@/features/navigation/enums';

export const commuteBoostPaths = {
  commuteBoostChallengesList: {
    path: '/commute-boost/challenges',
    name: 'commuteBoostChallengesList',
  },
  adminCommuteBoostChallenges: {
    path: '/admin/commute-boost/challenges',
    name: 'adminCommuteBoostChallenges',
  },
  adminCommuteBoostChallengesCreate: {
    path: '/admin/commute-boost/challenges/create',
    name: 'adminCommuteBoostChallengesCreate',
  },
  adminCommuteBoostChallengesEdit: {
    path: '/admin/commute-boost/challenges/edit/:challengeId',
    name: 'adminCommuteBoostChallengesEdit',
  },
  adminCommuteBoostRedeem: {
    path: '/admin/commute-boost/redeem',
    name: 'adminCommuteBoostRedeem',
  },
  adminCommuteBoostChallengesView: {
    path: '/admin/commute-boost/challenges/view/:challengeId',
    name: 'adminCommuteBoostChallengesView',
  },
  commuteBoostAbout: {
    path: '/commute-boost/about',
    name: 'commuteBoostAbout',
  },
};

export const commuteBoostRoutes = [
  {
    path: commuteBoostPaths.commuteBoostChallengesList.path,
    name: commuteBoostPaths.commuteBoostChallengesList.name,
    component: () => import('@/features/commute-boost/screens/ChallengesList.vue'),
    meta: {
      title: 'Commute Boost > Challenges',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.Private,
    },
  },
  {
    path: commuteBoostPaths.adminCommuteBoostChallenges.path,
    name: commuteBoostPaths.adminCommuteBoostChallenges.name,
    component: () => import('@/features/commute-boost/screens/ChallengesList.vue'),
    meta: {
      title: 'Commute Boost > Challenges',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.Private,
    },
  },
  {
    path: commuteBoostPaths.adminCommuteBoostChallengesCreate.path,
    name: commuteBoostPaths.adminCommuteBoostChallengesCreate.name,
    component: () => import('@/features/commute-boost/screens/CreateChallenge.vue'),
    meta: {
      title: 'Commute Boost > Create challenge',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.PrivateEmpty,
    },
  },
  {
    path: commuteBoostPaths.adminCommuteBoostChallengesEdit.path,
    name: commuteBoostPaths.adminCommuteBoostChallengesEdit.name,
    component: () => import('@/features/commute-boost/screens/CreateChallenge.vue'),
    meta: {
      title: 'Commute Boost > Edit challenge',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.PrivateEmpty,
    },
  },
  {
    path: commuteBoostPaths.adminCommuteBoostChallenges.path,
    name: commuteBoostPaths.adminCommuteBoostChallenges.name,
    component: () => import('@/features/commute-boost/screens/ChallengesList.vue'),
    meta: {
      title: 'Commute Boost > Challenges',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.Private,
    },
  },
  {
    path: commuteBoostPaths.adminCommuteBoostChallengesView.path,
    name: commuteBoostPaths.adminCommuteBoostChallengesView.name,
    component: () => import('@/features/commute-boost/screens/ChallengeDetails.vue'),
    meta: {
      title: 'Commute Boost > View challenge',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.Private,
    },
  },
  {
    path: commuteBoostPaths.adminCommuteBoostRedeem.path,
    name: commuteBoostPaths.adminCommuteBoostRedeem.name,
    component: () => import('@/features/commute-boost/screens/RedeemBoost.vue'),
    meta: {
      title: 'Commute Boost > Redeem',
      rolesToPermit: ROLE_COMMUTE_BOOST_ADMIN,
      nextIfUnauthorized: commuteBoostPaths.commuteBoostAbout.path,
      layout: Layouts.PrivateEmpty,
    },
  },
  {
    path: commuteBoostPaths.commuteBoostAbout.path,
    name: commuteBoostPaths.commuteBoostAbout.name,
    component: () => import('@/features/commute-boost/screens/About.vue'),
    meta: {
      title: 'Commute Boost > About',
      layout: Layouts.Private,
    },
  },
];
