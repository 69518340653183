export enum Methods {
  Post = 'POST',
  Delete = 'DELETE',
  Put = 'PUT',
  Get = 'GET',
}

export enum SurveyImageType {
  Email,
  ThankyouMessage,
}

export enum Headers {
  XRequestedWith = 'X-Requested-With',
  ContentType = 'Content-Type',
  CacheControl = 'Cache-Control',
  Pragma = 'Pragma',
  Authorization = 'Authorization',
}
